import logo from './logo.svg';
import './App.css';
import TopHeader from './Sections/TopHeader.js'
import HeroContent from './Sections/Hero.js'
import TixProductPage from './Sections/TixProductPage.js'
import AboutPage from './Sections/AboutPage.js'

function App() {
  return (
    <div className="app container lg:mx-auto">
      <header className="topHeader p-4 h55 sticky top-0 bg-black z-10">
        <TopHeader />
      </header>

      <section className="hero font-mono font-bebas p-4 bg-white text-black text-xl lg:text-2xl drop-shadow-md">
        <HeroContent />
      </section>
      <section className="tixPage bg-zinc-200 text-black pb-4">
        <TixProductPage />
      </section>
      <section className="aboutPage p-4 pt-8 pb-4">
        <AboutPage />
      </section>

      <section className="roadmapPage m-4 p-4 text-white bg-zinc-500">
        <article className="opacity-100">
        <h1 className="font-bold pb-4">2022 Roadmap Overview</h1>
        <ul className="list-disc ml-6">
          <li className="line-through"> Create art concept for kickstart drop </li>
          <li className="line-through"> Develop funding tokenomics </li>
          <li className="line-through"> Write whitepaper 1.0 </li>
          <li className="line-through"> Deliver website with whitepaper info and link </li>
          <li> Share website and whitepaper </li>
          <li> Marketing and promotions </li>
          <li> Creation of UB.LUV TIX contract </li>
          <li> Delivery of giveaway TIX supply </li>
          <li> Delivery of TIX mint website </li>
          <li> Open TIX public sale </li>
          <li> Deliver AMA with community </li>
          <li> Sell out TIX </li>
          <li> Deliver UB.LUV HRTS </li>
          <li> Deliver first UB.LUV POST drop </li>
          <li> Start work on UB.LUV LGNS </li>
          <li> Deliver new website </li>
        </ul>
        
        <hr className="mt-8 mb-8"/>

        <h1 className="text-xl font-thin">UB.LUV HRTS</h1>
        <h2 className="text-lg font-bold font-pt-sans">OG Community Collection, generative art collection, 1st year funding</h2>
        <i className="text-xs bg-white text-black pr-2 pl-2 rounded-xl">Expected launch, Jun 16th</i>
        <p className="mb-4">Once the TIX drop starts selling, the work on the UB.LUV HRTS collection is started, with launch expected for late Q2 2022.</p>
        <p className="mb-4">When minting HRTS, holders of TIX may choose to burn them for a free HRTS NFT, or hold for future redemption.</p>
        <p className="mb-4">This collection distribution is designed to build the community of collectors, fans and investors, which will enable funding the team’s ambitions for at least 1 year, with both sales and commission.</p>
        <p>This is to be a collection with generative elements, portraying Epic Hearts, and capturing the current moment of web3 memes with contemporary pop art envisioned to look good on digital and print formats, from pfps to accessories, clothes and walls.</p>

        <hr className="mt-8 mb-8"/>

        <h1 className="text-xl font-thin">UB.LUV LGNS</h1>
        <i className="text-xs bg-white text-black pr-2 pl-2 rounded-xl"> Expected first drop, August 2022</i>
        <p className="mb-4">Once HRTS is delivered, the work on UB.LUV LGNS is started, this is a multi-year project to build a collection over time based on collabs and residencies, embodying the uniqueness of the invited artist’s vision of legendary love into limited edition art.</p>
        <p className="mb-4">The intent is to generate higher-end art pieces and expand the unbreakable.love memes through the work of selected artists.</p>
        <p>This collection distribution will be limited to auctions and at times may accept TIX redemptions.</p>

        <hr className="mt-8 mb-8"/>

        <h1 className="text-xl font-thin">UB.LUV POST & SHOP</h1>
        <i className="text-xs bg-white text-black pr-2 pl-2 rounded-xl"> Expected first drop, 2022Q2</i>
        <p className="mb-4">Timely drops to celebrate special occasions and festive dates.</p>
        <p>There will always be perks and innovative ways for holders to get cool gifts and cards to their loved ones.</p>

      </article>
      </section>

      <section className="faqPage">
      </section>

      <section className="teamPage">
      </section>

      <section className="footer">
        <section className="socialMenu">
        </section>
      </section>
    </div>
  );
}

export default App;
