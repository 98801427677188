function TixProductPage() {
  return (
    <>
      <header className="p-4 ">
      <h1 className="text-xl font-thin">UB.LUV TIX</h1>
      <h2 className="text-lg font-bold font-pt-sans">Your ticket into eternal &amp; unbreakable love.</h2>
      </header>

      <div className="m-4 mt-1 mb-1 pt-4 pb-4 bg-zinc-600 text-white text-bold font-bebas text-2xl text-center cursor-wait brightness-100 hover:brightness-150">
        Minting soon
      </div>

      <article className="p-4">
      <h3 className="text-base font-bold">3.000 NFTs redeemable for perks and free mints into future UBLUV drops, and our seed funding.</h3>
      <p className="mb-4">This NFT features a limited edition art collection, and unlocks ultimate perks that may require burning.</p>
      
      <h2 className="font-pt-sans font-bold">How does it work?</h2>
      <ol className="list-decimal ml-6">
        <li>Mint TIX, get early access and fund the project kickstart</li>
        <li>then, burn your TIX for discount and free UB.LUV HRTS, the perky community collection</li>
        <li>...or, HODL your TIX for perks on ever cooler and rarer UB.LUV LGNS drops, the legendary love stories made by select commissioned artists, funded by TIX sales commissions</li>
      </ol>

      </article>

    <div className="m-4 mt-2 mb-2 pt-4 pb-4 bg-zinc-600 text-white text-bold font-bebas text-2xl text-center cursor-wait brightness-100 hover:brightness-150">
      Minting soon
    </div>
    </>
  );
}

export default TixProductPage;
