function Hero() {
  return (
    <>
        <h1><span className="text-neutral-600 text-lg">UBLUV is</span> unbreakable love.</h1>
        <h2>We deliver eternal magic for your love story.</h2>
    </>
  );
}

export default Hero;
