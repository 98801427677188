function AboutPage() {
  return (
    <>
      <h2 className="text-xl font-thin uppercase tb-4"> About UB.LUV </h2>
      <p>
        <b className="font-pt-sans">We are building a web3 native brand seeking to deliver the coolest and finest memes about love.</b><br />
        We use digital art, NFTs and partnerships to deliver high quality gifts for festive dates and special occasions.
      </p>
      <p className="pt-2">
        <b className="font-pt-sans">Never miss a special occasion.</b><br />
        Community holders enjoy life long access to perks and timely gifts.
      </p>
      <h3 className="font-pt-sans font-bold mt-4">NFT collections</h3>
      <ul className="list-disc ml-6">
        <li>3000 Early-access genesis tickets, burnable for ultimate perks (minting soon)</li>
        <li>10000 OG community collection, with yielding perks (June 16th, 2022)</li>
        <li>Awesome limited edition public sale seasonal collectibles (TBA)</li>
        <li>Rare, unique and 1/1 collabs on legendary high-end collections (TBA)</li>
      </ul>

      <h3 className="font-pt-sans font-bold mt-4">Utility for community holders</h3>
      <ul className="list-disc ml-6">
        <li>Earn yield from seasonal sales commissions</li>
        <li>Perks for seasonal gift collections</li>
        <li>Discount on partner giftshops</li>
      </ul>
    </>
  );
}
export default AboutPage;
