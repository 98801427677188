function TopHeader() {
  return (
    <>
        <section className="pageLogo">
          <h1>UBLUV</h1>
        </section>

        <section className="topNavMenu">
        </section>

        <section className="socialMenu">
        </section>
    </>
  );
}

export default TopHeader;
